import PropTypes from 'prop-types';

import withBindProps from 'core/hocs/withBindProps';

import Feed from 'core/components/Feed';
import { Indent, NegativeMobile } from 'core/components/Wrappers';

import Rug from 'site/components/Rug';
import MainBlockTitle from 'site/components/MainBlockTitle';
import Card2 from 'site/cards/Card2';

import {
  SIDE_INDENT_MOBILE,
  SMALL_INDENT,
} from 'site/constants';

import styles from './index.styl';

const Card2Type2 = withBindProps({ type: 2 })(Card2);


function MostCommentable({ content, isMobile }) {
  if (!content?.length) return null;

  if (isMobile) {
    return (
      <>
        <MainBlockTitle>Самое обсуждаемое</MainBlockTitle>
        <NegativeMobile>
          <Rug top={-25}>
            <Indent left={SIDE_INDENT_MOBILE} right={SIDE_INDENT_MOBILE}>
              <Feed
                content={content}
                card={Card2Type2}
                interitemSpacing={SMALL_INDENT}
                visibility='main_page'
              />
            </Indent>
          </Rug>
        </NegativeMobile>
      </>
    );
  }

  const matrixContent = [
    [content[0], content[3]],
    [content[1], content[4]],
    [content[2], content[5]],
  ];

  return (
    <>
      <style jsx>{`
        .${styles.row}
          margin -${SMALL_INDENT}px

        .${styles.card}
          margin ${SMALL_INDENT}px
      `}</style>
      <MainBlockTitle dataQa='feed-popular'>Самое обсуждаемое</MainBlockTitle>
      <Rug top={-20} bottom={40}>
        {matrixContent.map((topic, index) => (
          <div key={index} className={styles.row}>
            <div className={styles.card} data-qa='popular-card'>
              <Card2Type2 content={topic[0]} digitValue={index + 1} />
            </div>
            <div className={styles.card}>
              <Card2Type2 content={topic[1]} digitValue={index + 1 + matrixContent.length} />
            </div>
          </div>
        ))}
      </Rug>
    </>
  );
}

MostCommentable.propTypes = {
  content: PropTypes.array,
  isMobile: PropTypes.bool,
};

export default MostCommentable;
