import PropTypes from 'prop-types';

import TopicContent from 'core/components/TopicContent';
import withBreakpoint from 'core/hocs/withBreakpoint';

import {
  InRead,
  Native3,
  AdCenter,
} from 'site/components/Ads/desktop';

import {
  InPage,
  Content1,
  Content2,
  Content3,
  Content4,
  ContentSpec,
} from 'site/components/Ads/mobile';

import { capitalize } from 'site/utils';
// eslint-disable-next-line import/no-namespace
import * as Widgets from 'site/widgets';


const desktopAds = [
  {
    before: 200,
    after: 200,
    showOnlyOnce: true,
    renderer: AdCenter,
  },
  {
    before: 1200,
    after: 200,
    showOnlyOnce: true,
    renderer: InRead,
  },
  {
    before: 600,
    after: 200,
    renderer: Native3,
  },
  {
    before: 1200,
    after: 200,
    renderer: AdCenter,
  },
];

const mobileAds = [
  Content1,
  InPage,
  ContentSpec,
  Content2,
  Content3,
  Content4,
].map((renderer, index, ads) => ({
  before: 600,
  after: 200,
  showOnlyOnce: ads.length !== index + 1,
  renderer: renderer,
}));

const widgetBlocks = [
  'incut',
  'markdown',
  'number',
  'opinion',
  'photoGallery',
  'readMore',
];

const blocks = widgetBlocks.reduce((items, renderer) => {
  items[renderer] = (block, parentProps, ctx) => {
    const WidgetComponent = Widgets[capitalize(renderer)];

    return (
      <WidgetComponent
        block={block}
        parentProps={parentProps}
        ctx={ctx}
      />
    );
  };

  return items;
}, {});

function SiteTopicContent({ isMobile, content }) {
  const interitemSpacing = isMobile ? 20 : 42;

  return (
    <TopicContent
      interitemSpacing={interitemSpacing}
      desktopAds={desktopAds}
      mobileAds={mobileAds}
      blocks={blocks}
      content={content}
    />
  );
}

SiteTopicContent.propTypes = {
  isMobile: PropTypes.bool,
  content: PropTypes.object,
};

export default withBreakpoint(SiteTopicContent);
