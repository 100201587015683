import PropTypes from 'prop-types';

import Mjolnir from 'core/components/Mjolnir';
import SeeAll from 'site/components/SeeAll';
import { Indent, NegativeMobile } from 'core/components/Wrappers';

import withBindProps from 'core/hocs/withBindProps';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import { DESKTOP, MOBILE } from 'core/components/breakpoint/values';

import Card3 from 'site/cards/Card3';
import Card5 from 'site/cards/Card5';

import {
  SMALL_INDENT,
  SIDE_INDENT_MOBILE,
} from 'site/constants';

import MainBlockTitle from 'site/components/MainBlockTitle';

const HeroCard = withBindProps({ type: 2 })(Card3);
const Card = withBindProps({ type: 1 })(Card5);
const titleMap = {
  [DESKTOP]: MainBlockTitle,
  [MOBILE]: ({ children }) => (
    <Indent left={SIDE_INDENT_MOBILE} right={SIDE_INDENT_MOBILE}>
      <MainBlockTitle>{children}</MainBlockTitle>
    </Indent>
  ),
};


function MainVideos({ content, isMobile, breakpoint }) {
  if (!content?.length) return null;

  return (
    <>
      <NegativeMobile>
        <Mjolnir
          dataQa='main-videos'
          title='Видео'
          content={content}
          headerComponent={titleMap[breakpoint]}
          card={Card}
          heroCard={isMobile ? Card : HeroCard}
          interitemSpacing={SMALL_INDENT}
        />
      </NegativeMobile>
      <Indent top={SMALL_INDENT} />
      <SeeAll
        dataQa='main-videos-button'
        title='Все видео'
        link='/label/video'
      />
    </>
  );
}

MainVideos.propTypes = {
  content: PropTypes.array,
  isMobile: PropTypes.bool,
  breakpoint: breakpointPropTypes(),
};

export default MainVideos;
