import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';

import LogoIcon from 'site/icons/Logo';


function Logo({ theme, ...other }) {
  return (
    <LogoIcon color={theme.colors.primary} {...other} />
  );
}


Logo.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(Logo);
