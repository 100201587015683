import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';
import { Desktop, Mobile } from 'core/components/breakpoint';

import Link from 'core/components/Link';
import Social from 'core/components/Social';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import Texts from 'core/components/Texts';

import modelPropTypes, { personAttributes } from 'core/utils/prop-types/model';
import themePropTypes from 'core/utils/prop-types/theme';

import Info from './Info';

import styles from './index.styl';

function AuthorCard(props) {
  const {
    author,
    allAuthorsLink,
    theme,
    isDesktop,
  } = props;

  const {
    name,
    email,
    biography,
    type,
    job_title: jobTitle,
    social_networks: socialNetworks,
    is_archived: isArchived,
  } = author.attributes;

  const noBio = !biography && !socialNetworks?.length;
  const showOnlyName = noBio && !email && !jobTitle && type !== 'expert';

  const allAuthorsComponent = allAuthorsLink && (
    <Link
      to={allAuthorsLink}
      className={styles.allAuthorsLink}
      dataQa='all-authors-link'
      type='gray600Underlined'
    >
      Все авторы
    </Link>
  );

  return (
    <div className={cx(styles.authorCard, noBio && styles._noBio)}>
      <style jsx>{`
        .archivedAuthor
          font ${theme.texts.bodySmall.font}
      `}</style>
      <div className={styles.wrapper}>
        <div className={styles.nameWrapper}>
          <Texts
            type='author'
            tag='h1'
            className={styles.name}
            dataQa='author-name'
          >
            {name}
          </Texts>
          <Mobile>
            {allAuthorsLink && allAuthorsComponent}
          </Mobile>
        </div>
        {isArchived && <div className='archivedAuthor'>архивный автор</div>}
        {!showOnlyName && (
          <>
            <Info
              author={author}
              noBio={noBio}
              isDesktop={isDesktop}
            />
            <div className={styles.content}>
              {biography && (
                <MarkdownWrapper data-qa='author-bio'>
                  {biography}
                </MarkdownWrapper>
              )}
              <Social socials={socialNetworks} />
            </div>
          </>
        )}
      </div>
      <Desktop>
        {allAuthorsLink && (
          <div className={styles.allAuthorsWrapper}>
            {allAuthorsComponent}
          </div>
        )}
      </Desktop>
    </div>
  );
}

AuthorCard.propTypes = {
  /**
   * Данные автора.
   */
  author: modelPropTypes(personAttributes).isRequired,
  /**
   * Ссылка на страницу "Все авторы".
   */
  allAuthorsLink: PropTypes.string,
  /** @ignore */
  isDesktop: PropTypes.bool,
  /** @ignore */
  theme: themePropTypes(`{
    texts: {
      bodySmall: {
        font,
      }
    }
  `),
};

const AuthorCardWithHOCs = withTheme(withBreakpoint(AuthorCard));
AuthorCardWithHOCs.displayName = 'AuthorCard';

export { AuthorCard as StorybookComponent };
export default AuthorCardWithHOCs;
