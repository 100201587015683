import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';

import TopicHeaderTexts from 'core/components/TopicHeaderTexts';
import { Indent } from 'core/components/Wrappers';
import TopicTopline from 'core/components/TopicTopline';


function HeaderTop({ isMobile }) {
  return (
    <>
      <Indent bottom={isMobile ? 16 : 20}>
        <TopicTopline />
      </Indent>
      <TopicHeaderTexts />
    </>
  );
}

HeaderTop.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(HeaderTop);
