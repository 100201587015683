import PropTypes from 'prop-types';

import Link from 'core/components/Link';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { topicDateFormat } from 'core/utils/dates';

import withCard from 'core/hocs/withCard';

import mapTypes from './types';

import styles from './index.styl';

const requiredPayloadFields = [
  'headline',
  'list_headline',
  'published_at',
  'link',
];


function Card2(props) {
  const {
    content,
    breakpoint,
    theme: {
      animations: {
        hover: hoverAnimation,
      },
      colors: colorsAtoms,
      fonts: fontsAtoms,
      texts: {
        caption4: caption4Atoms,
      },
    },
    type,
    digitValue,
    position,
    utm,
  } = props;

  if (!content) return null;

  const {
    headline,
    list_headline: listHeadline,
    published_at: publishedAt,
    link,
  } = content.attributes;

  const {
    showDate,
    showPosition,
    whiteDate,
    smallFont,
  } = (mapTypes[type] || {})[breakpoint] || mapTypes[type] || {};

  const date = topicDateFormat(publishedAt);
  const fontStylesForSmallViewport = `400 14px/1.14 ${fontsAtoms.display}`;
  const to = utm ? link + utm : link;
  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .link
          display ${showPosition ? 'flex' : 'block'}
        .headline
          transition color ${hoverAnimation}
          font ${fontsAtoms.text}
          color ${caption4Atoms.color}
          .link:hover &
            color ${colorsAtoms.active1}
          :global(.ad-site-wrapper-small) &
            font ${smallFont ? fontStylesForSmallViewport : caption4Atoms.font}
      `}</style>
    </scope>
  );

  return (
    <div className={styles.card}>
      <style jsx>{`
        .${styles.date}
          font-family ${fontsAtoms.display}
          color ${whiteDate ? 'rgba(255, 255, 255, .4)' : colorsAtoms.hint}
        .${styles.position}
          font-family ${fontsAtoms.display}
          color ${colorsAtoms.active1}
          transition opacity ${hoverAnimation}
      `}</style>

      <Link
        to={to}
        type='secondary'
        className={scope.wrapClassNames('link')}
      >
        {showPosition && <span className={styles.position}>{digitValue || position + 1}</span>}
        <div className={scope.wrapClassNames('headline')} data-qa='card-headline'>
          {listHeadline || headline}
        </div>
        {showDate && <span className={styles.date}>{date}</span>}
      </Link>
      <scope.styles />
    </div>
  );
}

Card2.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([1, 2, 3, 4]),
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  breakpoint: breakpointPropTypes(),
  /** @ignore */
  isMobile: PropTypes.bool,
  /** Цифра, отображающая позицию карточки */
  digitValue: PropTypes.number,
  /** Позиция карточки */
  position: PropTypes.number,
  /** @ignore */
  theme: PropTypes.object,
  /** UTM-метка, добавляемая к ссылке в карточке */
  utm: PropTypes.string,
};

const Card = withCard(Card2);

Card.displayName = 'Card2';
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card2 as StorybookComponent };
