import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/hocs/withTheme';

import Rug from 'site/components/Rug';

import './index.styl';

const NativeStyles = ({ theme }) => {
  const {
    animations: {
      hover: hoverAnimation,
    },
    fonts: fontsAtoms,
    colors: colorsAtoms,
  } = theme;

  return (
    <Fragment>
      <style global jsx>{`
      .native-ad.card-read-more
        .ad_native_content-wrapper
          background ${colorsAtoms.content}

        .ad_native_img
          &:after
            transition opacity ${hoverAnimation}

        .ad_native_title
          font-family ${fontsAtoms.text}
          color ${colorsAtoms.primary}
          transition color ${hoverAnimation}

        .ad_native_wrapper
          &:hover
            .ad_native_title
              color ${colorsAtoms.active1}

        .ad_native_sponsored
          color ${colorsAtoms.primary400}
          font-family ${fontsAtoms.text}
    `}</style>
    </Fragment>
  );
};

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function cardStyles(Component) {
  return withTheme(props => {
    return (
      <div className={cx(
        'native-ad',
        'card-read-more',
        props.type && '_' + props.type,
      )}
      >
        <Rug>
          <NativeStyles {...props} />
          <Component {...props} />
        </Rug>
      </div>
    );
  });
}
