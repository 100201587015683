import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';

import Recommender from 'site/components/Recommender';

import { Indent } from 'core/components/Wrappers';

import { VERTICAL_INDENT,  VERTICAL_INDENT_MOBILE } from 'site/constants';


function Boroda(props) {
  const {
    showRecommender,
    isDesktop,
  } = props;

  const spacing = isDesktop ? VERTICAL_INDENT : VERTICAL_INDENT_MOBILE;

  return (
    <Indent top={spacing}>
      {showRecommender &&
        <Recommender />
      }
    </Indent>
  );
}

Boroda.propTypes = {
  showRecommender: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(Boroda);
