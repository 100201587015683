import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import withoutSSR from 'core/components/withoutSSR';
import withBreakpoint from 'core/hocs/withBreakpoint';

import Calendar from 'site/icons/Calendar';

import MobileDatepicker from './Mobile';


const DesktopDatepicker = React.lazy(() => import('./Desktop'));

const DesktopDatepickerWithHocs = withoutSSR(function(props) {
  return (
    <Suspense fallback={<Calendar />}>
      <DesktopDatepicker {...props} />
    </Suspense>
  );
});

function Datepicker({ isMobile, ...otherProps }) {
  if (isMobile) return <MobileDatepicker {...otherProps} />;

  return <DesktopDatepickerWithHocs {...otherProps} />;
}

Datepicker.propTypes = {
  isMobile: PropTypes.bool,
};

const DatepickerWithHOCs = withBreakpoint(Datepicker);
DatepickerWithHOCs.displayName = 'Datepicker';

export default DatepickerWithHOCs;
export { Datepicker as StorybookComponent };
