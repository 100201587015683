import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'core/libs/router';

import Link from 'core/components/Link';

import withCard from 'core/hocs/withCard';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import AdvTooltip from 'core/components/AdvTooltip';

import Mark from 'site/components/Mark';
import SiteImage from 'site/components/SiteImage';

import {
  imageVersionExists,
} from 'site/utils';

import mapTypes from './types';

import styles from './index.styl';

const requiredPayloadImports = ['image', 'review'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
  'ad_label',
  'topic_type',
  'advertiser_name',
  'link_with_announce_erid',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});


function Card3(props) {
  const {
    type,
    breakpoint,
    content,
    imageVersion,
    theme,
    isMobile,
    location,
    position,
  } = props;

  if (!content) return null;

  const {
    link_attrs: linkAttrs,
    remote_image: remoteImage,
  } = content.extra || {};

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: alternativeHeadline,
    ad_label: adLabel,
    topic_type: topicType,
    advertiser_name: advertiserName,
    link_with_announce_erid: linkWithAnnounceErid,
  } = content.attributes;

  const {
    image: {
      versions: {
        [imageVersion]: preCover,
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    review,
  } = relationships(content);

  const isMainPage = location.pathname === '/';
  const cover = imageVersionExists(preCover) ? preCover : originalCover;
  const shouldShowAdvTooltip = !!(isMainPage && position === 0 && linkWithAnnounceErid && advertiserName && adLabel);
  const topicLink = shouldShowAdvTooltip ? linkWithAnnounceErid : link;
  const {
    showVideoIcon,
    showAltHeadline,
    contentPadding,
    headlineSize,
    imageWidth,
    aspectRatio,
    smallFont,
  } = (mapTypes[type] || {})[breakpoint] || mapTypes[type] || {};

  const Icon = require('site/icons/Video').default;

  const imageSourceProp = remoteImage
    ? { url: remoteImage }
    : { src: cover, previewSrc: previewCover };

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .link
          &:hover
            .headline
            .altHeadline
              color ${theme.colors.active1}
            :global([id*="!"])
              stroke ${theme.colors.active1}
              transition stroke ${theme.animations.hover}

        .headline
        .altHeadline
          font-family ${theme.fonts.display}
          transition color ${theme.animations.hover}

        .headline
          font-size ${headlineSize}

          :global(.ad-site-wrapper-small) &
            font-size ${smallFont || headlineSize}
            font-weight ${smallFont ? 'normal' : 'bold'}
        `}</style>
    </scope>
  );

  return (
    <div className={styles.card}>
      <style jsx>{`
        .content
          padding ${contentPadding}
          transition background-color ${theme.animations.hover}
      `}</style>
      <div className={styles.cardInner}>
        <Link
          to={topicLink}
          type='secondary'
          className={cx(scope.wrapClassNames('link'), styles.link)}
          {...linkAttrs && {
            innerRef: node => {
              if (node) {
                linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
              }
            },
          }}
        >
          <div className={styles.markWrapper}>
            <Mark compact review={review} />
          </div>

          <SiteImage
            {...imageSourceProp}
            maxWidth={isMobile ? (imageWidth || 970) : imageWidth}
            aspectRatio={aspectRatio}
          />

          {showVideoIcon && topicType === 'video' && (
            <div className={styles.iconWrapper}>
              <Icon width={100} height={100} />
            </div>
          )}

          <div className={cx(styles.content, 'content')}>
            <div className={cx(styles.headline, scope.wrapClassNames('headline'))} data-qa='card-headline'>
              {listHeadline || headline}
            </div>

            {showAltHeadline &&
              <div className={cx(styles.altHeadline, scope.wrapClassNames('altHeadline'))}>
                {alternativeHeadline}
              </div>
            }
          </div>
        </Link>
      </div>
      <scope.styles />
      {shouldShowAdvTooltip && (
        <AdvTooltip text={advertiserName} adLabel={adLabel} />
      )}
    </div>
  );
}

Card3.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([1, 2, 3]),

  /** Переобложка изображения, которая будет использоваться в карточке вместо `original` */
  imageVersion: PropTypes.string,

  /** Позволяет контролировать соотношение сторон изображения карточки */
  imageRatio: PropTypes.number,

  /** @ignore */
  breakpoint: breakpointPropTypes(),

  /** @ignore */
  content: modelPropTypes(topicAttributes),

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  theme: PropTypes.object,

  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),

  position: PropTypes.number,
};

const Card = withCard(withRouter(Card3));

Card.displayName = 'Card3';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card3 as StorybookComponent };
