import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'core/components/Link';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';

import styles from './index.styl';

function SeeAll(props) {
  const {
    link,
    title,
    tough,
    isMobile,
    theme,
    dataQa,
  } = props;

  if (!(link && title)) return null;

  const Icon = require('site/icons/arrowRight').default;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .link
          font-family ${theme.fonts.display}
          color ${theme.colors.primary}
          transition color ${theme.animations.hover}
          :global([id*="$"])
            transition fill ${theme.animations.hover}

          &:hover
            color ${theme.colors.active1}
            :global([id*="$"])
              fill ${theme.colors.active1}
          &.big
            color ${theme.colors.active1}
            :global([id*="$"])
              fill ${theme.colors.active1}
      `}
      </style>
    </scope>
  );

  return (
    <Link
      to={link}
      className={cx(
        styles.link,
        tough && styles.big,
        isMobile && styles.mobile,
        scope.wrapClassNames('link', tough && 'big'),
      )}
      {...dataQa && { 'data-qa': dataQa }}
      type={'secondary'}
    >
      {title}
      <Icon
        className={cx(styles.icon, 'icon')}
        height={isMobile ? 13 : 16}
        width={isMobile ? 41 : 51}
      />
      <scope.styles />
    </Link>
  );
}

SeeAll.propTypes = {
  /** URL */
  link: PropTypes.string.isRequired,
  /** Текст */
  title: PropTypes.string.isRequired,
  /** Показывать с увеличенным шрифтом и цветом `active1`. */
  tough: PropTypes.bool,
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  dataQa: PropTypes.string,
};

const SeeAllWithHOCs = withBreakpoint(withTheme(SeeAll));
SeeAllWithHOCs.displayName = 'SeeAll';

export default SeeAllWithHOCs;

export { SeeAll as StorybookComponent };
