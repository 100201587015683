import PropTypes from 'prop-types';

import Page from 'core/components/Page';
import Bouesque from 'core/components/Bouesque';
import { Block } from 'core/components/Grid';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { Desktop, Mobile } from 'core/components/breakpoint';
import StickyPortal from 'core/components/StickyPortal';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent, Indent, NegativeMobile } from 'core/components/Wrappers';
import SelfPromo from 'core/components/SelfPromo';

import withBreakpoint from 'core/hocs/withBreakpoint';
import withTheme from 'core/hocs/withTheme';

import {
  Ad240x400,
  Ad240x400Second,
  Context,
  Parallax,
} from 'site/components/Ads/desktop';

import {
  Listing2,
  Listing3,
  Listing4,
} from 'site/components/Ads/mobile';

import { PartnersVertical } from 'site/components/Partners';

import MostPopular from 'site/components/MostPopular';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  SMALL_INDENT,
} from 'site/constants';

import Boroda from 'site/components/Boroda';

import Experts from './Experts';
import Videos from './Videos';
import Lifehacks from './Lifehacks';
import Numbers from './Numbers';
import Reviews from './Reviews';
import Selection from './Selection';
import MostCommentable from './MostCommentable';
import FirstBlock from './FirstBlock';
import Skeleton from './Skeleton';

import useMainContent from './useMainContent';

const extraIndent = 10;


function MainPage(props) {
  const {
    theme,
    isMobile,
    isDesktop,
    history,
  } = props;

  const {
    isLoading,
    firstBlock,
    selection,
    mostCommentable,
    experts,
    reviews,
    videos,
    numberOfTheDay,
    historyNumber,
    lifehacks,
  } = useMainContent(isDesktop, history);

  if (isLoading) return <Skeleton />;

  const breakpointProps = {
    isMobile,
    isDesktop,
    breakpoint: props.breakpoint,
  };

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Page>
      <PageIndent>
        <ColumnLayout
          rightColumn={(
            <>
              <Bouesque iconSize={24} />
              <Indent bottom={SMALL_INDENT} />
              <StickyPortal listenStickyRemoveEvent>
                <AdWrapper bottom={SMALL_INDENT}>
                  <Ad240x400 />
                  <SelfPromo />
                  <Indent bottom={SMALL_INDENT} />
                  <PartnersVertical />
                  <Indent bottom={vertical} />
                  <Ad240x400Second />
                </AdWrapper>
              </StickyPortal>
            </>
          )}
        >
          <Block>
            <FirstBlock topics={firstBlock} {...breakpointProps} />

            <Desktop>
              <Indent top={vertical} />
            </Desktop>

            <NegativeMobile>
              <Selection
                content={selection}
                theme={theme}
                {...breakpointProps}
              />
            </NegativeMobile>

            <Mobile>
              <Listing2 />
              <Indent top={vertical} />
            </Mobile>

            <MostCommentable content={mostCommentable} {...breakpointProps} />

            <Indent top={vertical + (isMobile ? extraIndent : 0)} />

            <Reviews content={reviews} {...breakpointProps} />
          </Block>
        </ColumnLayout>
      </PageIndent>

      <Desktop>
        <Parallax />
      </Desktop>

      <PageIndent>
        <ColumnLayout
          rightColumn={(
            <StickyPortal>
              <MostPopular />
            </StickyPortal>
          )}
        >
          <>
            <Experts content={experts} />

            <Indent top={vertical} />

            <Desktop>
              <Context />
            </Desktop>

            <Mobile>
              <Listing3 />
            </Mobile>

            <Indent top={vertical} />

            <Videos content={videos} {...breakpointProps} />

            <Mobile>
              <Indent top={vertical} />
              <Listing4 />
            </Mobile>

            <Indent top={vertical} />

            <Numbers
              numberOfTheDay={numberOfTheDay}
              historyNumber={historyNumber}
              {...breakpointProps}
            />

            <Indent top={isMobile ? vertical + extraIndent : 20} />

            <Lifehacks content={lifehacks} {...breakpointProps} />

            <Desktop>
              <AdWrapper top={vertical}>
                <Context count={2} />
              </AdWrapper>
            </Desktop>

            <Indent top={vertical} />

            <Boroda showRecommender />
          </>
        </ColumnLayout>
      </PageIndent>

    </Page>
  );
}

MainPage.propTypes = {
  firstBlock: PropTypes.object,
  selection: PropTypes.object,
  mostCommentable: PropTypes.array,
  experts: PropTypes.array,
  lifehacks: PropTypes.array,
  videos: PropTypes.array,
  numberOfTheDay: PropTypes.object,
  historyNumber: PropTypes.object,
  reviews: PropTypes.array,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  breakpoint: PropTypes.string,
  theme: PropTypes.object,
  history: PropTypes.object,
};

export default withBreakpoint(withTheme(MainPage));
