import { compose } from 'core/libs/recompose';
import withBindProps from 'core/hocs/withBindProps';

import Ad from 'core/components/Ad';
import AdBillboard from 'core/components/Ad/Billboard';
import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';

import cardStyles from './styles/card';
import cardReadMoreStyles from 'site/components/Ads/styles/card-read-more';

import { RIGHT_COLUMN_WIDTH, LAYOUT_MAX_WIDTH } from 'site/constants';

export const Billboard = withBindProps({
  name: 'Billboard',
  height: 250,
  blockId: '433128620',
  options: {
    p1: 'brzng',
    p2: 'y',
    pct: 'a',
    pli: 'a',
    plp: 'a',
    pop: 'a',
  },
})(AdBillboard);

export const Fullscreen = withBindProps({
  name: 'Fullscreen',
  blockId: '435722670',
  options: {
    p1: 'brokb',
    p2: 'emiu',
    pct: 'a',
    pli: 'a',
    plp: 'a',
    pop: 'a',
  },
})(Ad);

export const Ad240x400 = withBindProps({
  name: '240x400',
  height: 600,
  width: RIGHT_COLUMN_WIDTH,
  blockId: '433128560',
  stickyTemp: 3,
  options: {
    p1: 'bmewt',
    p2: 'emhk',
    pct: 'a',
    pli: 'a',
    plp: 'a',
    pop: 'a',
  },
})(Ad);

export const Ad240x400Second = withBindProps({
  name: '240x400_2nd',
  height: 600,
  width: RIGHT_COLUMN_WIDTH,
  lazy: true,
  blockId: '433128582',
  options: {
    p1: 'bqzpl',
    p2: 'ewqs',
    pct: 'a',
    pli: 'a',
    plp: 'a',
    pop: 'a',
  },
})(Ad);

export const Context = withBindProps({
  name: 'Context',
  height: 80,
  lazy: true,
  blockId: '433128780',
  options: {
    p1: 'buomd',
    p2: 'ewzc',
    pct: 'a',
  },
})(({ count = 1, ...props }) => (
  <Ad manualPuids={{ puid44: `context_item${count}` }} {...props} />
));

export const Sponsored = compose(
  withBindProps({
    name: 'Sponsored',
    blockId: '446888412',
    options: {
      p1: 'bwurt',
      p2: 'fomx',
      pct: 'c',
      pli: 'a',
      plp: 'a',
      pop: 'a',
    },
  }),
  sponsoredStyles
)(Ad);

export const SuperFooter = withBindProps({
  name: '100%x240',
  height: 240,
  lazy: true,
  blockId: '433128628',
  options: {
    p1: 'brznj',
    p2: 'fcuz',
    pct: 'a',
    pli: 'a',
    plp: 'a',
    pop: 'a',
  },
})(Ad);

export const Parallax = withBindProps({
  name: 'Parallax',
  height: 250,
  width: LAYOUT_MAX_WIDTH,
  lazy: true,
  blockId: '446888404',
  options: {
    p1: 'bwurs',
    p2: 'fhoe',
    pct: 'a',
  },
})(Ad);

export const AdCenter = withBindProps({
  name: 'Center',
  height: 240,
  lazy: true,
  blockId: '446888402',
  options: {
    p1: 'bwurr',
    p2: 'fomw',
    pct: 'a',
    pli: 'a',
    plp: 'a',
    pop: 'a',
  },
})(Ad);

export const InRead = withBindProps({
  name: 'inRead',
  height: 300,
  blockId: '433128744',
  //  C domId не забываем контролировать, чтобы InRead был всего один на страницу
  domId: 'in-read-banner',
  options: {
    p1: 'brznm',
    p2: 'fcvb',
    pct: 'a',
    pli: 'a',
    plp: 'a',
    pop: 'a',
  },
})(Ad);

export const Native3 = compose(
  withBindProps({
    name: 'native3',
    height: 180,
    blockId: '455471374',
    options: {
      p1: 'bxezz',
      p2: 'fjgk',
      pct: 'a',
      pli: 'a',
      plp: 'a',
      pop: 'a',
    },
  }),
  cardReadMoreStyles
)(Ad);

const Native1Props = {
  name: 'Native1',
  blockId: '435722644',
  options: {
    p1: 'buomb',
    p2: 'fhzr',
    pct: 'a',
    pli: 'a',
    plp: 'a',
    pop: 'a',
  },
};

const Native2Props = {
  name: 'Native2',
  blockId: '435722648',
  lazy: true,
  options: {
    p1: 'buomc',
    p2: 'fhzs',
    pct: 'a',
  },
};

export const PopUp = withBindProps({
  name: 'PopUp',
  blockId: '579502879',
  options: {
    pct: 'a',
    pli: 'a',
    plp: 'a',
    pop: 'a',
  },
})(Ad);

export const Native1Card3 = compose(
  withBindProps({ ...Native1Props, ...{ card: 3 } }),
  cardStyles
)(Ad);

export const Native1CardHorizontal = compose(
  withBindProps({ ...Native1Props, card: 'horizontal'  }),
  cardStyles
)(Ad);

export const Native2CardHorizontal = compose(
  withBindProps({ ...Native2Props, card: 'horizontal' }),
  cardStyles
)(Ad);

export const Native1Card5 = compose(
  withBindProps({ ...Native1Props, ...{ card: 5 } }),
  cardStyles
)(Ad);

export const Native2Card5 = compose(
  withBindProps({ ...Native2Props, ...{ card: 5 } }),
  cardStyles
)(Ad);

export const InGallery = withBindProps({
  name: 'InGallery',
  blockId: '555123459',
  disableAutoInit: true,
})(Ad);

export const ShowUp = withBindProps({
  name: 'Show-up',
  blockId: '579503978',
})(Ad);
