import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'core/components/Link';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';

import withCard from 'core/hocs/withCard';

import SiteImage from 'site/components/SiteImage';

import { imageVersionExists } from 'site/utils';

import {
  SIDE_INDENT_MOBILE,
} from 'site/constants';

import mapTypes from './types';

import styles from './index.styl';

const requiredPayloadImports = ['image', 'rubric'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
  'topic_type',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function Card5(props) {
  const {
    type,
    breakpoint,
    content,
    imageVersion,
    theme,
    isMobile,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
    topic_type: topicType,
  } = content.attributes;

  const {
    image: {
      versions: {
        [imageVersion]: preCover,
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    rubric: {
      title: rubricTitle,
    },
  } = relationships(content);

  const cover = imageVersionExists(preCover) ? preCover : originalCover;

  const {
    videoIconCentered,
    headlineSize,
    iconSize,
    imageWidth,
    aspectRatio,
    showAltHeadline,
  } = (mapTypes[type] || {})[breakpoint] || mapTypes[type] || {};

  const Icon = require('site/icons/Video').default;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .link
          &:hover
            .headline
            .altHeadline
              color ${theme.colors.active1}
            :global([id*="!"])
              stroke ${theme.colors.active1}

        .headline
        .altHeadline
          font-family ${theme.fonts.display}
          color ${theme.colors.primary}
          transition color ${theme.animations.hover}


        .headline
          font-size ${headlineSize}
          padding ${isMobile ? `0 ${SIDE_INDENT_MOBILE}px` : 0}

        .rubricTitle
          font-family ${theme.fonts.display}
          color ${theme.colors.hint}
          padding ${isMobile ? `0 ${SIDE_INDENT_MOBILE}px` : 0}
      `}</style>
    </scope>
  );

  return (
    <Link
      type='secondary'
      to={link}
      className={cx(styles.link, scope.wrapClassNames('link'))}
    >
      <style jsx>{`
        .overlay
          transition opacity ${theme.animations.hover}

        .iconWrapper
          top ${videoIconCentered ? '50%' : 'auto'}
          bottom ${videoIconCentered ? 'auto' : '16px'}
          left ${videoIconCentered ? '50%' : '16px'}
          transform ${videoIconCentered ? 'translate(-50%, -50%)' : 'none'}
      `}</style>

      <div className={styles.imageContent}>
        <SiteImage
          src={cover}
          previewSrc={previewCover}
          maxWidth={isMobile ? 970 : imageWidth}
          aspectRatio={aspectRatio}
        />
        <div className={cx(styles.overlay, 'overlay')} />
        {topicType === 'video' &&
          <div className={cx(styles.iconWrapper, 'iconWrapper')}>
            <Icon width={iconSize} height={iconSize} />
          </div>
        }
      </div>
      <div className={styles.textContent}>
        <div className={cx(styles.headline, scope.wrapClassNames('headline'))} data-qa='card-headline'>
          {listHeadline || headline}
        </div>
        {showAltHeadline && altHeadline &&
          <div className={cx(
            scope.wrapClassNames('altHeadline'),
            styles.altHeadline
          )}
          >
            {altHeadline}
          </div>
        }
        <div className={cx(styles.rubricTitle, scope.wrapClassNames('rubricTitle'))}>
          {rubricTitle}
        </div>
      </div>
      <scope.styles />
    </Link>
  );
}

Card5.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([1, 2]),

  /** @ignore */
  breakpoint: breakpointPropTypes(),

  /** @ignore */
  content: modelPropTypes(topicAttributes),

  /** Переобложка изображения, которая будет использоваться в карточке в карточке вместо `original` */
  imageVersion: PropTypes.string,

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  theme: PropTypes.object,
};


const Card = withCard(Card5);

Card.displayName = 'Card5';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card5 as StorybookComponent };
