import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'core/components/Link';
import Image from 'core/components/SmartImage';
import { Indent } from 'core/components/Wrappers';

import { Mobile } from 'core/components/breakpoint';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import { MOBILE_BIG, viewportBreakpoints } from 'core/components/breakpoint/viewport-values';
import withCard from 'core/hocs/withCard';
import withBindProps from 'core/hocs/withBindProps';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import TopicMetaData from 'site/components/TopicMetaData';

import Author from './Author';

import styles from './index.styl';


const requiredPayloadImports = ['image', 'authors', 'rubric', 'search_result'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  search_result: {
    title: '',
  },
});


function CardHorizontal(props) {
  const {
    content,
    imageWidth,
    aspectRatio,
    showAuthor,
    theme,
  } = props;

  const {
    colors: colorsAtoms,
  } = theme;

  if (!content) return null;

  const {
    remote_image: remoteImage,
  } = content.extra || {};

  const {
    link,
    headline,
    list_headline: listHeadline,
    published_at: publishedAt,
  } = content.attributes;

  const {
    image: {
      versions,
    },
    rubric: {
      title: rubricTitle,
    },
    search_result: {
      title: searchTitle,
    },
    authors,
  } = relationships(content);

  const {
    original: cover,
    thumbnail: previewCover,
  } = versions;

  const imageSourceProp = remoteImage
    ? { url: remoteImage }
    : { src: cover, previewSrc: previewCover };

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.headline}
          :global(b)
            color ${colorsAtoms.active1}
      `}</style>
    </scope>
  );

  const showImage = cover || remoteImage;

  return (
    <Link type='primary' to={link}>
      <style jsx>{`
        .${styles.card}
          &.${styles._withImage}
            :global(.desktop) &
              grid-template-columns ${imageWidth}px 1fr
            :global(.mobile) &
              grid-template-columns 1fr ${imageWidth}px
        .${styles.rubric}
          color ${colorsAtoms.gray600}
          background-color ${colorsAtoms.gray100}
        .${styles.divider}
          background-color ${colorsAtoms.divider}
          @media (max-width: ${viewportBreakpoints[MOBILE_BIG]})
            margin 15px -20px 0
      `}</style>
      <div className={cx(styles.card, showImage && styles._withImage)}>
        {showImage &&
          <Image
            {...imageSourceProp}
            className={styles.image}
            width={imageWidth}
            aspectRatio={aspectRatio}
          />
        }

        <div className={styles.content}>
          <div className={scope.wrapClassNames(styles.headline)} data-qa='card-headline'>
            <MarkdownWrapper inline>
              {searchTitle || listHeadline || headline}
            </MarkdownWrapper>
          </div>

          {showAuthor && (
            <>
              <Indent bottom={10} />
              <Author
                author={authors[0]}
                theme={theme}
              />
            </>
          )}

          {(rubricTitle || publishedAt) && (
            <div className={styles.meta}>
              {rubricTitle && <span className={styles.rubric}>{rubricTitle}</span>}
              {publishedAt && <TopicMetaData publishedAt={publishedAt} />}
            </div>
          )}
        </div>
      </div>
      <Mobile>
        <div className={styles.divider} />
      </Mobile>
      <scope.styles />
    </Link>
  );
}

CardHorizontal.propTypes = {
  /** Ширина изображения. */
  imageWidth: PropTypes.number,

  /** Соотношение изображения. */
  aspectRatio: PropTypes.number,

  /** Отображать автора. */
  showAuthor: PropTypes.bool,

  /** @ignore */
  content: modelPropTypes(topicAttributes),

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  theme: PropTypes.object,
};

CardHorizontal.defaultProps = {
  imageWidth: 180,
  aspectRatio: 1.5,
};

const Card = withCard(CardHorizontal);
export const CardHorizontalMobile = withBindProps({ imageWidth: 60, aspectRatio: 1 })(Card);

Card.displayName = 'CardHorizontal';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export { CardHorizontal as StorybookComponent };

export default Card;
