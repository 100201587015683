import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';
import queryString from 'core/libs/query-string';

import withBreakpoint from 'core/hocs/withBreakpoint';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import Author from 'site/components/Author';
import SocialShare from 'site/components/SocialShare';
import { Indent, TopicContentMaxWidth } from 'core/components/Wrappers';
import Mark from 'site/components/Mark';

import TopicHeaderMedia from './HeaderMedia';
import TopicHeaderTop from './HeaderTop';

const Sponsored = require('site/components/Ads/Sponsored').default;

const relationships = resolveRelationships(
  ['rubric', 'authors', 'review'],
  {},
);


function TopicHeader(props) {
  const {
    content,
    location: {
      search,
    },
    isCommentsPage,
    isMobile,
    isGameCompare,
  } = props;

  const {
    id,
    attributes: {
      link,
      is_comments_disabled: isCommentsDisabled,
    },
  } = content;

  const {
    rubric: {
      root_slug: rubricRootSlug,
    },
    authors,
    review,
  } = relationships(content);

  const spacing = 20;

  const page = +queryString.parse(search).page || 1;

  return (
    <>
      <TopicContentMaxWidth>
        <TopicHeaderTop />
        <Sponsored />
        {!isCommentsPage && !isGameCompare && (
          <SocialShare
            xid={id}
            topicLink={link}
            isCommentsDisabled={isCommentsDisabled}
          />
        )}
      </TopicContentMaxWidth>
      {!isCommentsPage && !isGameCompare && (
        <>
          <Indent top={isGameCompare ? 0 : 24} />
          {page === 1 && <TopicHeaderMedia content={content} />}
          {rubricRootSlug === 'experts' && (
            <Indent top={spacing} >
              <Author author={authors[0]} type={2} />
            </Indent>
          )}
          {page === 1 && (
            <Indent top={isMobile ? 24 : 36}>
              <Mark review={review} />
            </Indent>
          )}
        </>
      )}
    </>
  );
}

TopicHeader.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  location: PropTypes.object.isRequired,
  isCommentsPage: PropTypes.bool,
  isMobile: PropTypes.bool,
  isGameCompare: PropTypes.bool,
};

export default withRouter(withBreakpoint(TopicHeader));
