import PropTypes from 'prop-types';

import withBindProps from 'core/hocs/withBindProps';

import Drum from 'core/components/Drum';
import Link from 'core/components/Link';

import MainBlockTitle from 'site/components/MainBlockTitle';
import Card3 from 'site/cards/Card3';

import styles from './index.styl';

const Card3Type3 = withBindProps({ type: 3 })(Card3);


export default function ReviewsMobile(props) {
  return (
    <>
      <div className={styles.mobileHeader}>
        <MainBlockTitle>Обзоры</MainBlockTitle>
        <MainBlockTitle>
          <Link to='/review' type='secondary'>Все</Link>
        </MainBlockTitle>
      </div>
      <Drum
        content={props.content}
        card={Card3Type3}
        spaceBetween={20}
        itemWidthMobile={280}
        dataQa='reviews'
      />
    </>
  );
}

ReviewsMobile.propTypes = {
  content: PropTypes.array,
};
