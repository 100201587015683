import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { rawContentQuery } from 'core/queries/searchPage';

import CoreSearchPage from 'core/components/SearchPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import withBreakpoint from 'core/hocs/withBreakpoint';
import { DESKTOP, MOBILE } from 'core/components/breakpoint/values';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import InnerSideColumn from 'site/components/InnerSideColumn';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  Listing2,
} from 'site/components/Ads/mobile';
import Boroda from 'site/components/Boroda';

import CardHorizontalDesktop, { CardHorizontalMobile } from 'site/cards/CardHorizontal';

const breakpointCards = {
  [DESKTOP]: CardHorizontalDesktop,
  [MOBILE]: CardHorizontalMobile,
};

function SearchPage({ breakpoint, location, history }) {
  const card = breakpointCards[breakpoint];

  const { data, isLoading } = useRequest(rawContentQuery({
    location,
    history,
    card,
  }));

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn showBoesque={false} />}>
        <CoreSearchPage
          isLoading={isLoading}
          rawContent={data}
          ad1={SuperFooter}
          ad2={Context}
          ad1Mobile={Listing1}
          ad2Mobile={Listing2}
          iconSize={24}
          card={card}
        />
        <Boroda showRecommender />
      </ColumnLayout>
    </PageIndent>
  );
}

SearchPage.propTypes = {
  breakpoint: breakpointPropTypes(),
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withBreakpoint(SearchPage);
