import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { rawAuthorsQuery } from 'core/queries/authorsPage';

import Texts from 'core/components/Texts';
import { Indent } from 'core/components/Wrappers';
import AuthorsPage from 'core/components/AuthorsPage';
import withBreakpoint from 'core/hocs/withBreakpoint';

import ListPageLayout from 'site/components/ListPageLayout';

import styles from './index.styl';


function Authors({ isDesktop, ...props }) {
  const { data: rawAuthors, isLoading } = useRequest(rawAuthorsQuery(props));

  return (
    <ListPageLayout showRecommender>
      <Texts
        className={styles.title}
        type='author'
        tag='h1'
        dataQa='all-authors-title'
      >
        Все авторы
      </Texts>
      <Indent top={isDesktop ? 30 : 20} />
      <AuthorsPage rawAuthors={rawAuthors}  isLoading={isLoading} />
    </ListPageLayout>
  );
}

Authors.propTypes = {
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(Authors);
