import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';
import withBindProps from 'core/hocs/withBindProps';

import RecommendationWidget from 'core/components/RecommendationWidget';

import Header from 'site/components/Header';


function SiteRecommender({ isMobile }) {
  return (
    <RecommendationWidget
      blockId={isMobile ? '08a1d7a0e1d545c49af096f027a7e118' : '9ba626b748d04bf8833f4d0e0a1532de'}
      title='Самое интересное'
      headerComponent={withBindProps({ type: 'h2' })(Header)}
    />
  );
}

SiteRecommender.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SiteRecommender);
