import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Image from 'core/components/SmartImage';
import Link from 'core/components/Link';

import modelPropTypes, { personAttributes } from 'core/utils/prop-types/model';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';
import withBindProps from 'core/hocs/withBindProps';
import {
  DESKTOP,
  MOBILE,
} from 'core/components/breakpoint/values';

import TopicMetaData from 'site/components/TopicMetaData';

import { getAvatar } from 'site/utils';

import {
  SMALL_INDENT,
} from 'site/constants';

import types from './types';
import styles from './index.styl';

const avatarMargin = {
  [DESKTOP]: 20,
  [MOBILE]: 12,
};


function Author(props) {
  const {
    content,
    topicData,
    type,
    theme: {
      animations: {
        hover: hoverAnimation,
      },
      colors: colorsAtoms,
      texts: {
        caption1: caption1Atoms,
        bodySmall: bodySmallAtoms,
      },
    },
    breakpoint,
  } = props;

  if (!content) return null;

  const {
    name,
    slug,
    job_title: jobTitle,
  } = content.attributes;

  const {
    noAvatar,
    avatarWidth,
    overlayed,
    jobTitleAsMeta,
    topLine,
    bottomLine,
    asLink,
    fatName,
    opinionFont,
    noMargin,
    aspectRatio,
    topName,
    nameFontSize,
    jobTitleFontSize,
  } = (types[type] || {})[breakpoint] || types[type] || {};

  const avatar = getAvatar(content);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.link}
          max-width ${overlayed ? avatarWidth + 'px' : 'initial'}

          &:hover .${styles.name}
          &:hover .jobTitle
            color ${overlayed ? '#fff' : colorsAtoms.active1}

        .${styles.name}
        .jobTitle
          transition color ${hoverAnimation}

        .${styles.name}
          font ${bodySmallAtoms.font}
          color ${overlayed ? '#fff' : bodySmallAtoms.color}

        .jobTitle
          font ${caption1Atoms.font}
          color ${overlayed ? '#fff' : caption1Atoms.color}
      `}</style>
    </scope>
  );

  const Wrapper = asLink
    ? withBindProps({
      to: '/authors/' + slug,
      className: scope.wrapClassNames(styles.link),
    })(Link)
    : Fragment;

  return (
    <Wrapper>
      <div className={cx(
        styles.author,
        topName && styles.topName,
        overlayed && styles._overlayed
      )}
      >
        <style jsx>{`
          .${styles.line}
            background-color ${colorsAtoms.divider}

          .avatar
            margin-right ${overlayed ? 0 : avatarMargin[breakpoint] + 'px'}

          .${styles.authorInfo}
            padding ${overlayed ? SMALL_INDENT + 'px' : 0}

          .${styles.overlay}
            background-color ${colorsAtoms.active1}
            transition opacity ${hoverAnimation}
        `}</style>

        {topLine && <div className={cx(styles.line, styles.topLine)} />}
        {!noAvatar && <div className='avatar'>
          <Image
            src={avatar}
            aspectRatio={aspectRatio || 1}
            {...{
              [overlayed ? 'maxWidth' : 'width']: avatarWidth,
            }}
          />
        </div>}
        <div className={styles.authorInfo}>
          <div
            className={cx(
              styles.name,
              scope.wrapClassNames(styles.name),
              noMargin && styles.noMargin
            )}
          >
            <span
              className={cx(
                fatName && styles.fat,
                nameFontSize && styles.nameSize,
                opinionFont && styles.opinionName,
              )}
            >
              {name}
            </span>
            {jobTitle && !jobTitleAsMeta && <span>{`, ${jobTitle}`}</span>}
          </div>
          {jobTitleAsMeta && jobTitle && (
            <div className={scope.wrapClassNames('jobTitle')}>
              <span
                className={cx(
                  opinionFont && styles.opinionJobTitle,
                  jobTitleFontSize && styles.jobTitleSize
                )}
              >
                {jobTitle}
              </span>
            </div>
          )}
          {!jobTitleAsMeta && (
            <TopicMetaData
              commentsCount={topicData.commentsCount}
              publishedAt={topicData.publishedAt}
            />
          )}
        </div>
        {overlayed && <div className={styles.overlay} />}
        {bottomLine && <div className={cx(styles.line, styles.bottomLine)} />}
      </div>
      <scope.styles />
    </Wrapper>
  );
}

Author.propTypes = {
  /** Данные автора, соответствующие модели `person` swagger-схемы. */
  content: modelPropTypes(personAttributes),
  /** Данные топика. */
  topicData: PropTypes.shape({
    /** Количество комментариев. */
    commentsCount: PropTypes.number,
    /** Дата публикации. */
    publishedAt: PropTypes.string,
  }),
  /** Тип компонента. */
  type: PropTypes.oneOf([0, 1, 2, 3, 4]),
  /** @ignore */
  breakpoint: breakpointPropTypes(),
  /** @ignore */
  theme: PropTypes.object,
};

Author.defaultProps = {
  type: 0,
};

const AuthorWithHOCs = withBreakpoint(withTheme(Author));
AuthorWithHOCs.displayName = 'Author';

export { Author };
export default AuthorWithHOCs;
