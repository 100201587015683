import PropTypes from 'prop-types';

import Feed from 'core/components/Feed';
import Drum from 'core/components/Drum';

import withBindProps from 'core/hocs/withBindProps';
import withReplaceCardWithAd from 'core/hocs/withReplaceCardWithAd';

import { Indent, NegativeMobile } from 'core/components/Wrappers';
import SeeAll from 'site/components/SeeAll';
import { Native2CardHorizontal } from 'site/components/Ads/desktop';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Card3 from 'site/cards/Card3';
import CardHorizontalDesktop from 'site/cards/CardHorizontal';

import { SMALL_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';

import MainBlockTitle from 'site/components/MainBlockTitle';

const Card3Type3 = withBindProps({ type: 3 })(Card3);


function Lifehacks({ content, isDesktop }) {
  if (!content?.length) return null;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .container
          padding 0 ${isDesktop ? '' : SIDE_INDENT_MOBILE + 'px' }
      `}</style>
    </scope>
  );

  if (isDesktop) {
    return (
      <>
        <Feed
          content={content}
          dataQa='lifehacks'
          title='Лайфхаки'
          headerComponent={MainBlockTitle}
          card={CardHorizontalDesktop}
          cardsReplacement={{
            1: withReplaceCardWithAd(Native2CardHorizontal),
          }}
          columns={1}
          interitemSpacing={20}
        />
        <Indent top={SMALL_INDENT} />
        <SeeAll link='/lifehack' title='Все лайфхаки' />
      </>
    );
  }

  return (
    <>
      <NegativeMobile>
        <Drum
          containerClass={scope.wrapClassNames('container')}
          content={content}
          title='Лайфхаки'
          headerComponent={withBindProps({ marginBottom: -5, marginLeft: SIDE_INDENT_MOBILE })(MainBlockTitle)}
          card={Card3Type3}
          interitemSpacing={20}
          itemWidthMobile={280}
        />
      </NegativeMobile>
      <scope.styles />
    </>
  );
}

Lifehacks.propTypes = {
  content: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export default Lifehacks;
