import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withBreakpoint from 'core/hocs/withBreakpoint';
import withTheme from 'core/hocs/withTheme';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import Grid from 'core/components/Grid';
import PremiumTopicHeader from 'core/components/PremiumTopicHeader';
import { Indent } from 'core/components/Wrappers';

import SocialShare from 'site/components/SocialShare';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import styles from './headerPremium.styl';


function TopicHeaderPremium(props) {
  const {
    content,
    isMobile,
    location: {
      pathname,
    },
    theme: {
      controls: {
        topicHeaderTexts: topicHeaderTextsAtoms,
        topicContent: topicContentAtoms,
      },
    },
    infinityIndex,
  } = props;

  const {
    id,
    attributes: {
      link,
      announce,
    },
  } = content;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const isCommentsPage = pathname.substr(pathname.lastIndexOf('/') + 1) === 'comments';
  const isPremiumInInfinity = infinityIndex !== 0;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.lead}
          font ${topicHeaderTextsAtoms.lead.font}
          color ${topicHeaderTextsAtoms.lead.color}
          letter-spacing ${topicHeaderTextsAtoms.lead.letterSpacing || 0}
      `}</style>
    </scope>
  );

  return (
    <>
      {isPremiumInInfinity && (
        <PremiumTopicHeader
          content={content}
          isPremiumInInfinity
        />
      )}
      <Grid {...!isMobile && { maxWidth: topicContentAtoms.widgetsMaxWidth }}>
        {!!announce && (
          <MarkdownWrapper className={scoped.wrapClassNames(styles.lead, isPremiumInInfinity && styles._premiumInInfinity)}>
            {announce}
          </MarkdownWrapper>
        )}
      </Grid>
      <Indent top={vertical} />
      {!isCommentsPage && (
        <Grid maxWidth={topicContentAtoms.widgetsMaxWidth}>
          <SocialShare
            topicLink={link}
            xid={id}
          />
          <Indent bottom={vertical} />
        </Grid>
      )}
      <scoped.styles />
    </>
  );
}

TopicHeaderPremium.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  isMobile: PropTypes.bool,
  location: PropTypes.object.isRequired,
  theme: PropTypes.object,
  infinityIndex: PropTypes.number,
};

export default withRouter(withBreakpoint(withTheme(TopicHeaderPremium)));
