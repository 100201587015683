import {
  SuperFooter,
  Context,
  Native1CardHorizontal,
  Native2CardHorizontal,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  Listing2,
  Listing3,
  Listing4,
  ListingSpec,
} from 'site/components/Ads/mobile';

import withReplaceCardWithAd from 'core/hocs/withReplaceCardWithAd';

import {
  DESKTOP,
  MOBILE,
} from 'core/components/breakpoint/values';


export default [
  {
    [DESKTOP]: {
      ad: SuperFooter,
      cardsReplacement: {
        3: withReplaceCardWithAd(Native1CardHorizontal),
      },
    },
    [MOBILE]: {
      ad: Listing1,
    },
  },
  {
    [DESKTOP]: {
      ad: Context,
      adProps: {
        count: 1,
      },
      cardsReplacement: {
        3: withReplaceCardWithAd(Native2CardHorizontal),
      },
    },
    [MOBILE]: {
      ad: ListingSpec,
    },
  },
  {
    [DESKTOP]: {
      ad: Context,
      adProps: {
        count: 2,
      },
    },
    [MOBILE]: {
      ad: Listing2,
    },
  },
  {
    [DESKTOP]: {
      ad: Context,
      adProps: {
        count: 3,
      },
    },
    [MOBILE]: {
      ad: Listing3,
    },
  },
  {
    [DESKTOP]: {
      ad: Context,
      adProps: {
        count: 4,
      },
    },
    [MOBILE]: {
      ad: Listing4,
    },
  },
];
